import React from 'react';
import {Helmet} from "react-helmet";
import Layout from "../../components/layout";
import { Link } from 'gatsby';
import Iframe from 'react-iframe';
import AwesomeSlider from 'react-awesome-slider';
import { Title, Testonimal, Twitter } from "../../components/Globals";
import 'react-awesome-slider/dist/styles.css';
// import 'react-awesome-slider/src/styles.js';

const SalesforceImpementationServicesPage = () => {

  return (
        <Layout>
           <Helmet>
        <title>Salesforce Implementation Services | Cymetrix Software</title>
        <meta name="description" content="Cymetrix Software offers Salesforce implementation services in USA, India, UK and Japan. We have a 100+ team of Salesforce certified team to help you in business transformation."/>
      <meta name="keywords" content="cymetrix,salesforce crm software implementation partners india,salesforce cpq partner india,salesforce.com crm partners india,salesforce partners in india,salesforce consultants india,salesforce marketing cloud partners india,salesforce partners in mumbai,salesforce partners in USA,salesforce implementation partners usa,SALESFORCE PARTNERS INDIA,salesforce consultants india, Cymetrix, cymetrixsoft, Cymetrixsoftware"/>
      <link rel="canonical" href="https://www.cymetrixsoft.com/services/salesforce-implementation-services/" />
      </Helmet>
            <div className="salesforce-implementation-services-background flex flex-col w-full">
                <p className="block text-base xl:text-3xl xl:px-48 xl:mt-24 md:mt-16 sm:mt-24 lg:text-xl md:text-xl sm:text-lg sma:text-base
                text-white text-uppercase text-center">
                TRANSFORM YOUR BUSINESS WITH SALESFORCE CRM IMPLEMENTATION PARTNERS USA					
                </p>
                <p className="block text-base xl:text-xl md:mt-2 lg:text-lg md:text-base sm:text-base sma:text-base
                text-white text-uppercase text-center">
                    Consolidate your business efforts to be more transparent with one easy-to-use platform
                </p>
            </div>

            <div className="relative max-w-6xl mx-auto" style={{backgroundColor: '#f6f6f6'}}>
                <div className="flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-2 md:py-24 justify-center">
                    <div className="sma:mx-3 relative mt-12 lg:mt-0 flex flex-col justify-center">
                        <div className="flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none">
                            <img
                                className="h-64 w-64"
                                src={require('./../../images/services/saleforceApiImg.png')}
                                alt="Hero"
                                />
                        </div>
                    </div>
                    <div className="relative lg:w-8/12 flex-shrink-0 lg:text-lef mx-auto justify-center">
                        <h1 className="font-black text-3xl leading-snug max-w-3xl text-hover font-serif">
                            Salesforce Implementation Services
                        </h1><br />
                        <hr className="border-solid border-black mb-1" style={{borderWidth: 0.1}}/>
                        <p className="font-semibold">
                        To assist businesses in their digital transformation Cymetrix Software provides Salesforce Implementation services.<strong> With the help of a Leading Salesforce Implementation Partner in USA boost your ROI. We integrate Salesforce within your business to bring progressive results.</strong> From strategic roadmap and package evaluation to set-up, configuration, and customization, Cymetrix takes a holistic approach and is equipped with certified and experienced resources to deliver best-fit Salesforce solutions.							
                        </p><br/>
                        <p className="font-medium">
                        Cymetrix Software is a trusted <Link to="https://www.cymetrixsoft.com/services/salesforce-consulting" className="underline">Salesforce Consulting Partner</Link> and implements the best solution that quickly delivers real value for the business. Our Salesforce implementation services approach includes:
                        </p>

                        <ul className="list-disc list-inside">
                            <li className="my-2 font-thin">Feasibility analysis and readiness assessment</li>
                            <li className="my-2 font-thin">Business process mapping and requirements gathering</li>
                            <li className="my-2 font-thin">Identification of most suitable Salesforce product for the business</li>
                            <li className="my-2 font-thin">Business process re-engineering</li>
                            <li className="my-2 font-thin">Setup, configuration, implementation</li>
                            <li className="my-2 font-thin">Guidance for adoption strategy</li>
                        </ul>
                        <div className="bg-transparent hover:border-blue-500 text-gray-700 text-base font-thin w-64 py-3 px-2 border border-yellow-500 hover:border-transparent rounded mb-8 uppercase max-w-sm mt-5 text-center">
                            <Link target="_blank"
                                to="/contact-us">Contact Cymetrix</Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="product-background w-full flex flex-col items-center" title="Background image of Salesforce Implementation Services" alt="
Salesforce Implementation Service Background  | Cymetrix Software
">
                <div className="max-w-6xl mx-4 my-16">
                    <h1 className="text-white text-3xl text-left sma-xl font-thin">Salesforce Implementation Services by Cymetrix</h1>
                    <div class="flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-10 md:py-14">
                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">DISCOVERY</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div className="mt-2 text-base text-gray-300 text-center">Before Salesforce implementation, companies require an in-depth analysis of their existing business processes. To assist businesses to meet their requirements our salesforce certified consultants suggest an implementation strategy and guide these processes to Salesforce functionality
</div>
                                <div className="mt-2 text-lg mx-5 ">
                                <ul className="list-disc">
                                    <li className=" text-gray-300"><span className="text-base text-gray-300">Detailed analysis of current business processes and mapping to Salesforce features</span></li>
                                    <li className="text-gray-300"><span className="text-base text-gray-300">Determine licensing needs of your organization to optimize your investment</span></li>
                                    <li className=" text-gray-300"><span className="text-base text-gray-300">In-depth migration path from the current system to Salesforce SALESFORCE SETUP & CONFIGURATION
</span></li>
                                    </ul>
                                    </div>

                            </div>
                        </div>

                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">SALESFORCE SETUP & CONFIGURATION</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div className="mt-2 text-base text-gray-300 text-center">Achieve business process automation with Salesforce setup, configuration, and customization. Salesforce CRM Implementation includes, among others, custom-designed workflows, access setups, approval processes, custom reports, and dashboards. Our Salesforce expertise includes:</div>
                                <div className="mt-2 text-lg mr-75">
                                <ul className="list-disc ">
                                    <li className=" text-gray-300 "><span className="text-base text-gray-300">Sales Cloud</span></li>
                                    <li className="text-gray-300"><span className="text-base text-gray-300">Services Cloud</span></li>
                                    <li className=" text-gray-300"><span className="text-base text-gray-300">Marketing Cloud</span></li>
                                    </ul>
                                    </div>
                            </div>
                           
                        </div>

                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">SALESFORCE CUSTOMIZATION</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div class="mt-2 text-base text-gray-300 text-center">We help fit unique business work flows in Salesforce implementation.</div>
                                <div class="mt-2 text-base text-gray-300 text-center"><Link to="/services/salesforce-customization-services" className="underline" >Salesforce customization Services</Link></div>
                            </div>
                        </div>

                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">SALESFORCE INTEGRATION</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div class="mt-2 text-base text-gray-300 text-center">We integrate internal as well as customer facing applications to achieve a synchronized and collaborative business environment.</div>
                                <div class="mt-2 text-base text-gray-300 text-center"><Link to="/services/sdfc-integration-services/" className="underline" >Salesforce Integration Services</Link></div>
                            </div>
                        </div>

                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">DATA MIGRATION TO SALESFORCE</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div class="mt-2 text-base text-gray-300 text-center">Our team can tackle complex data migration challenges within optimal time limits. Our data migration services include data extraction, cleansing, de-duplication, standardization, validation and import into Salesforce.</div>
                            </div>
                        </div>

                        <div class="mt-4 md:w-1/2 lg:w-1/2">
                            <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col items-center sm:mx-2 sm:p-3 md:p-6">
                                <div className="text-white text-center text-lg">SUPPORT, TRAINING AND DOCUMENTATION</div>
                                <hr className="h-px bg-white w-24 mt-4 mb-3"></hr>
                                <div class="mt-2 text-base text-gray-300 text-center">Cymetrix - Salesforce implementation partner in USA has a team of certified and experienced Salesforce experts, administrators, developers, and testers to provide the very critical ongoing maintenance and continuous improvement support services for Salesforce.</div>
                                <div class="mt-2 text-base text-gray-300 text-center"><Link to="/services/salesforce-maintenance-support-services" className="underline">Salesforce Support Services</Link></div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row justify-center">
                        <div className="bg-transparent hover:border-blue-500 text-base font-thin w-64 py-3 px-2 border border-yellow-500 hover:border-transparent rounded mb-8 uppercase max-w-sm mt-5 text-center text-white">
                            <Link target="_blank"
                                to="/contact-us">Contact Cymetrix</Link>
                        </div>
                    </div>
                </div>
            </div>

            <Testonimal />
            <div className="lg:mt-32 mt-20">
                <Twitter />
            </div>
            
        </Layout>
    );
}

export default SalesforceImpementationServicesPage; 